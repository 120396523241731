import React from 'react';
import ReactDOM from 'react-dom';
import './css/index.css';
import {Navbar} from './components/navBarComponent';
import {DESKTOP, MOBILE, PageModeContext} from './common/context';
import {NAVITEMS} from './common/constants.js';
import {PageWrapper} from './components/content.jsx';

class Container extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listHide: true,
            preTime: Date.now()
        };
        this.handleClickPage = this.handleClickPage.bind(this);
        this.handleClickBtn = this.handleClickBtn.bind(this);
    }

    handleClickPage() {
        if (!this.state.listHide) {
            this.setState({listHide: true})
        }
    }

    handleClickBtn() {
        let now = Date.now();
        if (now - this.state.preTime > 420) {
            this.setState({listHide: !this.state.listHide});
            this.setState({preTime: now});
        }
    }

    static contextType = PageModeContext;

    render() {
        return [
            <PageWrapper key="page-wrapper" onClick={this.handleClickPage}/>,
            <Navbar key="navbar" navItems={NAVITEMS} isFixed={true} prefix={this.context === MOBILE ? "#anc-" : "#"}
                    isMobile={this.context === MOBILE} onClick={this.handleClickBtn}
                    listHide={this.state.listHide}/>
        ]
    }
}

ReactDOM.render(
    <PageModeContext.Provider value={window.innerWidth < 736 ? MOBILE : DESKTOP}>
        <Container isMobile={!!MOBILE}/>
    </PageModeContext.Provider>,
    document.getElementById('root')
);