import React, {Suspense} from 'react';
import '../css/index.css';

const Label = React.lazy(() => import('../components/tag.jsx'));

class ParaText extends React.Component {
    render() {
        const content = this.props.content;
        const classList = 'card-text';
        return (
            <Suspense fallback={<div className='cancel-overflow'>
                <div className='p-class-long bColor div-center'/>
                <div className='p-class-long bColor div-center'/>
                <div className='p-class-short bColor div-center'/>
            </div>}>
                <Label tag={'p'} desp={content} classList={classList}/>
            </Suspense>
        )
    }
}

class LiText extends React.Component {
    render() {
        const content = this.props.content;
        return (
            <Suspense fallback={<div className='cancel-overflow'>
                <div className='l-class-long bColor'/>
                <div className='l-class-long bColor'/>
                <div className='l-class-short bColor'/>
            </div>}>
                <Label tag={'li'} content={content}/>
            </Suspense>
        );
    }
}

export class IndexCard extends React.PureComponent {
    render() {
        return (
            <div className="index-card">
                <div className="row index-box-up">
                    <div className="ele-center text-center">
                        <h1><strong>{this.props.info.title}</strong></h1>
                        <h4>{this.props.info.subTitle}</h4>
                    </div>
                </div>
                <div className="row index-box-up">
                    <div className="card-body">
                        <div className="index-box app-animate always-animate">
                            <div className="img">
                                <img src={this.props.info.image} className="img-fluid index-img" alt="bg"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export class ProductCard extends React.PureComponent {
    render() {
        const info = this.props.info;
        const buttonClassList = 'btn btn-info btn-lg rounded-pill app-btn';
        const imgClassList = 'app-icon shadow';
        const h1ClassList = 'font-bold';
        const alt = 'logo';
        return (
            <div className="app-slide fp-noscroll" data-anchor={info.href}>
                <div className="row box-pg product-margin">
                    <div className="col-md app-animate">
                        <div className="img-box">
                            <Suspense fallback={<div className='proPic-svg bColor'/>}>
                                {
                                    [<Label key={'mockup'} tag={'img'} alt={'mockup'} classList={'app-mock'} src={info.intro[1]}/>,
                                        <Label key={'bg'} tag={'img'} alt={'bg'} classList={'app-bg'} src={info.intro[0]}/>,
                                        <Label key={'bg-mask'} tag={'img'} alt={'bg-mask'} classList={'hidden-mask'}
                                               src={info.intro[0]}/>]
                                }
                            </Suspense>
                        </div>
                    </div>
                    <div className="col-md app-animate">
                        <Suspense fallback={<div className='proIcon-svg bColor'/>}>
                            <Label tag={'img'} src={info.logo} classList={imgClassList} alt={alt}/>
                        </Suspense>
                        <div className="card-body app-intro">
                            <Suspense fallback={<div className='proTitle-svg bColor'/>}>
                                <Label tag={'h1'} title={info.name} classList={h1ClassList}/>
                            </Suspense>
                            <Suspense fallback={<div className='cancel-overflow'>
                                <div className='p-class-long bColor'/>
                                <div className='p-class-long bColor'/>
                                <div className='p-class-short bColor'/>
                            </div>}>
                                <Label tag={'p'} classList={'card-text'} desp={info.desp}/>
                            </Suspense>
                            <div className="btn-box">
                                <Suspense fallback={<div className='proButton-svg bColor'/>}>
                                    <Label tag={'button'} href={info.btnHref} classList={buttonClassList}
                                           btnHint={info.btnHint}
                                           icon={info.icon}/>
                                </Suspense>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export class BannerCard extends React.PureComponent {
    render() {
        return (
            <div className="banner-box">
                <Suspense fallback={<div className='banner-svg bColor'/>}><Label tag={'img'} src={this.props.src}
                                                                                 alt={this.props.alt}
                                                                                 classList={this.props.classList}/></Suspense>
            </div>
        )
    }
}


export class CareerCard extends React.PureComponent {
    render() {
        return (
            <div className="col">
                <div className="row">
                    <div className="card-body text-center career-text">
                        <Suspense fallback={<div className='proTitle-svg bColor div-center'/>}>
                            <Label tag={'h1'} title={this.props.info.title} classList={this.props.info.titleClass}/>
                        </Suspense>
                        <ParaText content={this.props.info.subTitle}/>
                        <Suspense fallback={<div className='proButton-svg bColor div-center'/>}>
                            <Label tag={'button'} href={this.props.info.href} classList={this.props.info.btnClass}
                                   btnHint={this.props.info.btnHint} icon={''}/>
                        </Suspense>
                    </div>
                </div>
            </div>)
    }
}


export class JobCard extends React.PureComponent {
    render() {
        return (
            <div className="col-sm-6">
                <div className="card job-card">
                    <div className="card-body">
                        <Suspense fallback={<div className='h3-class bColor'/>}>
                            <Label tag={'h3'} title={this.props.info.title} classList={'card-title'}/>
                        </Suspense>
                        <ul className="card-text">
                            {
                                this.props.info.requires.map(element => {
                                    return <LiText key={'pos-' + element} content={element}/>
                                })
                            }
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}


export class IntroCard extends React.PureComponent {
    render() {
        return (
            <div className="card-body intro-text">
                <Suspense fallback={<div className='aboutTitle-svg bColor'/>}>
                    <Label tag={'h1'} title={this.props.info.title} classList={this.props.info.class}/>
                </Suspense>
                <ParaText content={this.props.info.desp}/>
            </div>
        )
    }
}


export class PicCard extends React.PureComponent {
    render() {
        return (
            <div className='span-box'>
                <div className="card-body text-center intro-text">
                    <Suspense fallback={<div className='aboutTitle-svg bColor'/>}>
                        <Label tag={'h1'} classList={this.props.class.titleClass} title={this.props.class.title}/>
                    </Suspense>
                </div>
                <div className="w-100"/>
                {
                    this.props.info.map((element, index) => {
                        return (
                            <div key={'pic-' + index} className="col-sm-3 text-center">
                                <Suspense fallback={<div className='aboutPic-svg rounded-circle bColor'/>}>
                                    <Label tag={'img'} src={element.src} classList={this.props.class.imgClass}
                                           alt='loading'/>
                                </Suspense>
                                <Suspense fallback={<div className='aboutText-svg bColor'/>}>
                                    <Label tag={'p'} classList={this.props.class.fontClass} desp={element.desp}/>
                                </Suspense>
                            </div>
                        )
                    })
                }
            </div>
        )
    }
}

