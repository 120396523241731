import React from 'react';
import logo_png from '../images/logo.png';
import logo_white_png from '../images/logo_white.png';
import {PageModeContext} from '../common/context';

export class ProductsNavbar extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.handleChangeParent = this.handleChangeParent.bind(this);
    }

    handleClick() {

    }

    handleChangeParent() {

    }

    render() {
        const prefix = this.props.prefix ? this.props.prefix : '';
        return (
            <nav className="navbar navbar-expand-sm navbar-light bg-light pro-nav product-nav" id={"product-header"}>
                <div className="collapse navbar-collapse justify-content-between">
                    <NavbarItemList key={"products-nav"} navItems={this.props.navItems} center={this.props.center}
                                    prefix={prefix} isHeader={false} onClick={this.handleClick}
                                    changeParent={this.handleChangeParent}/>
                </div>
            </nav>
        )
    }
}


export class Navbar extends React.Component {
    static contextType = PageModeContext;

    constructor(props) {
        super(props);
        this.state = {
            show: !this.props.listHide,
            bgColor: true,
            activeItem: 'Home',
            preTime: Date.now(),
            logo: {
                href: "#home",
                name: "YUANBO",
                src: window.location.hash.endsWith("home") || window.location.hash === "" || this.props.isMobile ? logo_white_png : logo_png,
            }
        };
        this.handleScroll = this.handleScroll.bind(this);
        this.handleChangeParent = this.handleChangeParent.bind(this);
    }

    handleScroll() {
        let now = Date.now();
        let top = document.documentElement.scrollTop || document.body.scrollTop;
        let homeAnc = document.getElementById("anc-home").clientHeight;
        if (now - this.state.preTime > 300) {
            this.setState({preTime: now});
            let productsAnc = document.getElementById("anc-products").clientHeight + homeAnc;
            let careersAnc = document.getElementById("anc-careers").clientHeight + productsAnc;
            let aboutAnc = document.getElementById("anc-about").clientHeight + careersAnc;
            // homeAnc.height();
            if (top < homeAnc) {
                window.history.pushState({status: 0}, '', '#anc-home');
            } else {
                if (top < productsAnc) {
                    window.history.pushState({status: 0}, '', '#anc-products');
                    this.setState({activeItem: 'Products'});
                } else if (top < careersAnc) {
                    window.history.pushState({status: 0}, '', '#anc-careers');
                    this.setState({activeItem: 'Careers'});
                } else if (top < aboutAnc) {
                    window.history.pushState({status: 0}, '', '#anc-about');
                    this.setState({activeItem: 'About'});
                }
            }
        }
        if (top < homeAnc) {
            this.setState({activeItem: 'Home', bgColor: true});
        } else {
            this.setState({bgColor: false});
        }
    }

    componentDidMount() {
        window.addEventListener("hashchange", this.handleHashChange);
        window.addEventListener("scroll", this.handleScroll);
    }

    handleChangeParent(newItem) {
        this.setState({activeItem: newItem});
    }

    render() {
        const logoInfo = this.state.logo;
        const className = "navbar fixed-top navbar-expand-md";
        const navId = "menu-nav";
        const prefix = this.props.prefix ? this.props.prefix : '';
        return (
            <nav className={[className, this.state.bgColor ? 'bg-blue navbar-dark' : 'bg-white navbar-light'].join(' ')}
                 id="header">
                <a className="navbar-brand" href={logoInfo.href}>
                    <img src={logo_white_png} height='32' alt={logoInfo.name}
                         className={['header-logo', this.state.bgColor ? '' : 'hidden'].join(' ')}/>
                    <img src={logo_png} height='32' alt={logoInfo.name}
                         className={['header-logo', !this.state.bgColor ? '' : 'hidden'].join(' ')}/></a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target={"#" + navId}
                        aria-controls={navId} aria-expanded="false" aria-label="Toggle navigation"
                        onClick={() => this.props.onClick()}>
                    <span className={!this.props.listHide ? 'fa fa-close' : 'navbar-toggler-icon'} id="nav-btn"/>
                </button>
                <div
                    className={["navbar-collapse collapse justify-content-end", this.state.bgColor ? 'bg-blue' : 'bg-white'].join(' ')}
                    id={navId}>
                    <NavbarItemList key={"header-nav"} navItems={this.props.navItems} prefix={prefix} isHeader={true}
                                    onClick={() => this.props.onClick()} changeParent={this.handleChangeParent}
                                    activeItem={this.state.activeItem}/>
                </div>
            </nav>
        )
    }
}


class NavbarItemList extends React.PureComponent {
    render() {
        const rows = [];
        const isAlignCenter = this.props.center;
        const prefix = this.props.prefix ? this.props.prefix : '';
        const name = isAlignCenter ? "navbar-nav mx-auto" : "navbar-nav";
        const isHeader = this.props.isHeader;
        this.props.navItems.forEach(element => {
            rows.push(
                <NavbarItem key={"nav-" + element.name} name={element.name} href={element.href}
                            isActive={element.isActive} prefix={prefix}
                            onClick={() => this.props.onClick()} isHeader={isHeader}
                            changeParent={this.props.changeParent} activeItem={this.props.activeItem}/>
            );
        });
        return (<ul className={name}>{rows}</ul>);
    }
}


class NavbarItem extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.href + "-entry-nav",
            isActive: this.props.isActive
        };
        this.handleHashChange = this.handleHashChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    handleHashChange() {
        let currentHash = window.location.hash;
        // Add condition to prevent re-rendered when hash change.
        if (!this.props.isHeader && currentHash.startsWith("#products")) {
            this.setState({isActive: false});
            if (currentHash === "#products" && (this.props.href === "approach")) {
                this.setState({isActive: true})
            }
            if (currentHash.endsWith(this.props.href)) {
                this.setState({isActive: true})
            }
        }
    }

    handleClick() {
        this.props.changeParent(this.props.name);
        this.props.onClick();
    }

    componentDidMount() {
        window.addEventListener("hashchange", this.handleHashChange);
    }

    render() {
        const name = this.props.name;
        const href = this.props.href;
        const prefix = this.props.prefix ? this.props.prefix : '';
        const activeLink = this.state.isActive ? "nav-link active" : "nav-link";
        const isHeader = this.props.isHeader;
        return (
            isHeader ? <li className={this.props.activeItem === this.props.name ? 'nav-item active' : 'nav-item'}
                           id={name + "-nav"} data-menuanchor={href}>
                    <a className='nav-link' href={prefix + href} onClick={this.handleClick}>{name}</a> <span
                    className="line"/>
                </li> :
                <a className={activeLink} href={prefix + href} id={this.state.id} onClick={this.handleClick}>{name}</a>
        )
    }
}



