// Index info
import img_home from "../images/home/home.svg";
import {productsBg, productsIogo, productsMock} from "./images";
import img_open from "../images/careers/img_open.jpg";
import img_various from "../images/careers/img_various.jpg";
import img_outdoor from "../images/careers/img_outdoor.jpg";
import img_fruits from "../images/careers/img_fruit.jpg";

export const INDEX_INFO = {
        subTitle: "Professional short video tools provider",
        title: "Where Stories Live",
        image: img_home
};

// Career info
export const CAREERS_INFO = {
        subTitle: "Our team is a welcoming group passionate about creating customer happiness. Join the team!",
        title: "Open Positions",
        href: "mailto:hr@uuchat.io",
        btnHint: "JOIN US",
        titleClass: 'font-bold',
        btnClass: 'btn btn-info btn-lg rounded-pill app-btn'
};

export const ABOUT_INFO = {
    title: "Introduction",
    desp: [
        "YB Group is the mobile app developers who believe in open source technology. And is a global premium partner of Apple, Google and Facebook. We are hoping to create a better and more pleasant experience for users.",
        // "The company in the hope of realizing more convenient mobile internet services for the world, thus creating a better and more pleasant experience for its users."
    ],
    class:'font-weight-bold text-center'
};

// Change links and name here
export const NAVITEMS = [
    {href: "home", name: "Home", isActive: true},
    {href: "products", name: "Products"},
    {href: "careers", name: "Careers"},
    {href: "about", name: "About"},
];

// Product page info
export const PRODUCTS_INFO = [
    {
        href: "approach",
        name: "Approach",
        logo: productsIogo[0],
        intro: [productsBg[0], productsMock[0]],
        icon: "fa fa-apple",
        isActive: true,
        desp: "Approach is a daily self-care video journal that helps you to overcome anxiety and depression by expressing your daily feelings and thoughts. Tracking your daily journals and merge those day-to-day journals to a visual journey of your life.",
        btnHint: "App Store",
        btnHref: "https://apps.apple.com/cn/app/id1541769086"
    },
    {
        href: "uuchat",
        name: "UUChat",
        logo: productsIogo[1],
        intro: [productsBg[1], productsMock[1]],
        icon: "fa fa-play",
        desp: "UUChat is a fully open source, hackable platform for building and running a modern online customer service system",
        btnHint: "VIEW MORE",
        btnHref: "https://uuchat.io"
    },
    {
        href: "Moodpress",
        name: "Moodpress",
        logo: productsIogo[2],
        intro: [productsBg[2], productsMock[2]],
        icon: "fa fa-android",
        desp: "Write you mood diary with 5 seconds",
        btnHint: "Android",
        btnHref: "https://play.google.com/store/apps/details?id=com.selfcare.diary.mood.tracker.moodpress"
    },
    {
        href: "flaskstate",
        name: "Flask State",
        logo: productsIogo[3],
        intro: [productsBg[3], productsMock[3]],
        desp: "A lightweight chart plugin for displaying machine state data in your web application.",
        icon: "fa fa-github",
        btnHint: "GitHub",
        btnHref: "https://github.com/yoobool/flask-state"
    }
];

// Careers info
export const POSITION_INFO = [
    {
        "title": "Global Social Media",
        "requires": ["Regularly upgrade product content on social networks",
            "Make product activity scheme and gain high-quality users",
            "Assist in the development of ad plan, optimize ASO keywords"],
        "isActive": true
    },
    {
        "title": "Android Development Engineer",
        "requires": ["Responsible for the research and development of mobile apps with high-quality coding",
            "Coordinate with product managers for in-depth involvement in demand discussion and feature definition of Android apps",
            "Refactoring coding structures, for performance optimization and user experience enhancement"]
    },
    {
        "title": "iOS Development Engineer",
        "requires": ["Responsible for the design, research and development, and technological risk control of iOS apps",
            "Cooperate with product managers and back-end engineers for design and review of project requirements",
            "Enhance and optimize code stability, performance and extensibility"]
    },
    {
        "title": "Java Back-end Engineer",
        "requires": ["Responsible for the system analysis and design, and implementation of core code",
            "Responsible for implementing back-end services according based on app business demands",
            "Offer practicable solutions which tackle performance bottlenecks or structural defects of core business systems"]
    }
];

// Images info
export const IMG_INFO = [
    {src: img_open, desp: 'Open and relaxing'},
    {src: img_various, desp: 'Various interest code'},
    {src: img_outdoor, desp: 'Outdoor foot print'},
    {src: img_fruits, desp: 'Fruits and snacks'}
];

// Environment info
export const ENVIRONMENT_INFO = {
    title: 'Environment',
    titleClass: 'font-weight-bold',
    fontClass: 'py-2 card-text',
    imgClass: 'rounded-circle pic-card'
};
