import React, {Suspense} from "react";
import {
    ABOUT_INFO,
    CAREERS_INFO,
    ENVIRONMENT_INFO,
    IMG_INFO,
    INDEX_INFO,
    POSITION_INFO,
    PRODUCTS_INFO
} from "../common/constants";
import {BannerCard, CareerCard, IndexCard, IntroCard, JobCard, PicCard, ProductCard} from "./pageCard";
import {MOBILE, PageModeContext} from "../common/context";
import {ProductsNavbar} from "./navBarComponent";
import img_career from "../images/careers/banner_career.jpg";
import img_about from "../images/careers/banner_about.jpg";

const ReactFullpage = React.lazy(() => import('@fullpage/react-fullpage'));

// import ReactFullpage from "@fullpage/react-fullpage";

class JobSlide extends React.PureComponent {
    static contextType = PageModeContext;

    render() {
        let eles = POSITION_INFO.map(element => {
            return <JobCard key={"job-" + element.title} info={element}/>
        });
        return (
            <div className="container-fluid static-height">
                <div id="job-slider" className="carousel slide" data-ride="carousel">
                    <ol className="carousel-indicators career-pos">
                        <li data-target="#job-slider" data-slide-to="0" className="active"/>
                        <li data-target="#job-slider" data-slide-to="1"/>
                    </ol>
                    <div className="carousel-inner" role="listbox">
                        <div className="carousel-item active">
                            <div className="row">
                                {eles.slice(0, 2)}
                            </div>
                        </div>
                        <div className="carousel-item">
                            <div className="row">
                                {eles.slice(2, 4)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

class PageContent extends React.PureComponent {
    static contextType = PageModeContext;

    render() {
        return (
            <div id="page-wrapper" onClick={this.props.onClick()}>
                <div className="section active fp-noscroll" id="anc-home">
                    <IndexCard info={INDEX_INFO}/>
                </div>
                <div className="section fp-noscroll" id="anc-products">
                    <ProductsNavbar navItems={PRODUCTS_INFO} center={true} prefix={"#products/"}/>
                    {
                        PRODUCTS_INFO.map(element => {
                            return <ProductCard key={"card-" + element.name} info={element}/>
                        })
                    }
                </div>
                <div className="section" id="anc-careers">
                    <BannerCard classList={'img-banner'} src={img_career} alt={"career"}/>
                    <div className="row">
                        <CareerCard info={CAREERS_INFO}/>
                    </div>
                    <div className="row box-pg">
                        {
                            this.context === MOBILE ? POSITION_INFO.map(element => {
                                return <JobCard key={"job-" + element.title} info={element}/>
                            }) : <JobSlide/>}
                    </div>
                </div>
                <div className="section" id="anc-about">
                    <BannerCard classList={'img-banner img-about-banner'} src={img_about} alt={"about"}/>
                    <div className="row box-pg">
                        <IntroCard info={ABOUT_INFO}/>
                    </div>
                    <div className="row box-pg">
                        <PicCard info={IMG_INFO} class={ENVIRONMENT_INFO}/>
                    </div>
                    <div className="footer-card">
                        <p className="text-muted text-center">Copyright © 2015 - 2021 Yuanbo Tech LLC.</p>
                    </div>
                </div>
            </div>)
    }
}

export class PageWrapper extends React.Component {
    static contextType = PageModeContext;

    handleAfterLoad(origin, destination) {
        let header = document.getElementById("header");
        let menuNav = document.getElementById('menu-nav');
        let headerLogo = document.getElementsByClassName('header-logo');
        const color = destination.index === 0 ? "bg-blue" : "bg-white";
        header.className = `navbar fixed-top navbar-expand-md navbar-light bg-light ${color}`;
        // eslint-disable-next-line
        menuNav.className = 'navbar-collapse collapse justify-content-end ${color}';
        if (destination.index === 0) {
            headerLogo[1].className = 'hidden header-logo';
            headerLogo[0].className = 'header-logo';
            let eles = document.getElementsByClassName("always-animate");
            eles[0].classList.remove("app-animate");
            setTimeout(() => {
                eles[0].classList.add("app-animate")
            }, 500);
        } else {
            headerLogo[0].className = 'hidden header-logo';
            headerLogo[1].className = 'header-logo';
        }

    }

    render() {
        return (
            this.context === MOBILE ? <PageContent onClick={() => this.props.onClick}/> :
                <Suspense fallback={<div className="index-card init-pos">
                    <div className="row index-box-up">
                        <div className="ele-center text-center">
                            <h1><strong>{INDEX_INFO.title}</strong></h1>
                            <h4>{INDEX_INFO.subTitle}</h4>
                        </div>
                    </div>
                </div>}>
                    <ReactFullpage
                        licenseKey={'OPEN-SOURCE-GPLV3-LICENSE'}
                        menu={'#menu-nav'}
                        scrollingSpeed={1000}
                        anchors={["home", 'products', "careers", "about"]}
                        navigationTooltips={['approach', 'flaskstate', 'uuchat', 'moodpress']}
                        sectionsColor={["#00B8e7", "#FFFFFF", "#FFFFFF", "#FFFFFF"]}
                        slideSelector={'.app-slide'}
                        responsiveWidth={736}
                        // responsiveHeight={900}
                        slidesNavigation={false}
                        controlArrows={false}
                        scrollOverflow={true}
                        paddingTop={"72px"}
                        lazyLoading={false}
                        afterLoad={this.handleAfterLoad}
                        render={({state, fullpageApi}) => {
                            return (
                                <PageContent onClick={() => this.props.onClick}/>
                            );
                        }
                        }
                    />
                </Suspense>
        );
    }
}
